@font-face {
  font-family: "Mitr";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Mitr Medium"), local("Mitr-Medium"),
    url(https://fonts.gstatic.com/s/mitr/v5/pxiEypw5ucZF8c8bJJfecnFHGPc.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

body {
  font-family: "Mitr";
  margin: 0;
  background-color: #ebba31;
  overflow-x: hidden;
}

.container {
  position: fixed;
  z-index: 1;
  width: 200vw;
  height: 200vh;
  overflow: hidden;
  margin-left: 50%;
  margin-top: 0px;
  left: -100vw;
  top: -45%;
}

.welcomeText {
  position: relative;
  width: 300px;
  margin: 0 auto;
  text-align: center;
  margin-top: 20px;
  color: white;
}
.warning {
  position: relative;
  width: 300px;
  margin: 0 auto;
  text-align: center;
  margin-top: 20px;
  animation: fadein 4.5s;
  color: white;
}

.Button {
  position: relative;
  background-color: white;
  width: 150px;
  height: 40px;
  margin-left: 50%;
  left: -75px;
  padding-top: 20px;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
}
.ButtonDesk {
  position: relative;
  background-color: yellow;
  width: 150px;
  height: 40px;
  margin-left: 50%;
  left: -75px;
  padding-top: 10px;
  margin-top: 40px;
}
.wrapper {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  position: absolute;
}

.wrapperMobile {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url("../images/background.jpg");
  background-position: center center;
  background-size: cover;
}

.QrWrapper {
  position: absolute;
  width: 400px;
  height: 550px;
  z-index: 2;
  margin-top: 37vh;
  margin-left: 50%;
  left: -200px;
  text-align: center;
}

.QrWrapper img {
  position: relative;
  width: 200px;
  height: auto;
  margin-left: 50%;
  left: -100px;
  animation: fadein 4.5s;
}

.QrWrapper h1 {
  position: relative;
  font-size: 50px;
  margin-left: 0 auto;
  color: white;
  font-family: "Mitr";
  animation: fadein 3s;
}

@keyframes fadeinH1 {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Instructions {
  position: absolute;
  width: 100%;
  z-index: 5;
}
.linecontainer {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
.TitleIntructions {
  padding: 5px;
  position: relative;
  text-align: center;
  margin: 0 auto;
  color: aliceblue;
  font-size: 45px;
  margin-top: -100px;
}
ol {
  padding-left: 0px;
}
.Instructions li {
  margin-bottom: 5px;
  color: aliceblue;
  font-size: 40px;
  text-align: center;
  list-style-type: none;
}

.VideoCanvas {
  position: absolute;
  z-index: 0;
  height: auto;
}
.Instructionswrapper {
  position: absolute;
  width: 50%;
  margin-left: 25%;
  z-index: 2;
  margin-top: 10%;
}
.Objects {
  position: absolute;
  width: 500px;
  height: 500px;
}

@media only screen and (max-width: 600px) {
  body,
  .wrapperMobile {
    overflow-x: hidden;
  }
  .container {
    top: -50%;
  }
  .VideoCanvas {
    position: fixed;
    z-index: 0;
  }
  .Instructions {
    position: absolute;
    width: 100%;
    z-index: 2;
  }
  .Instructionswrapper {
    margin-top: 20%;
  }
  .TitleIntructions {
    margin-top: 15%;
    font-size: 40px;
  }
  .Instructions li {
    margin-bottom: 2px;
    color: aliceblue;
    font-size: 30px;
    text-align: center;
    list-style-type: none;
  }
}
